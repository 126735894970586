<template>
  <div>
    <template v-if="fetchingSubject">
      <Loading />
    </template>
    <template v-else>
      <v-fade-transition mode="out-in">
        <v-row v-if="show">
          <v-col cols="12">
            <v-btn
              :to="{ name: 'Workplace' }"
              rounded
              dark
              :class="`main-${subject.color}-bg`"
            >
              <v-icon>mdi-chevron-left</v-icon>Workplace
            </v-btn>
          </v-col>
          <v-col
            xl="3"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            v-for="action in actions"
            :key="action.name"
          >
            <ActionCard :subject="subject" :action="action" />
          </v-col>
        </v-row>
      </v-fade-transition>
      <v-fade-transition mode="out-in" v-if="!show">
        <Breadcrumbs transition="fade" baseRoute="/workplace" />
      </v-fade-transition>

      <v-fade-transition mode="out-in" v-if="!show">
        <router-view></router-view>
      </v-fade-transition>
    </template>
  </div>
</template>

<script>
export default {
  name: "Subject",
  components: {
    ActionCard: () => import("@/components/ActionCard.vue"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    Loading: () => import("@/components/Loading"),
  },

  computed: {
    fetchingSubject() {
      return this.$store.state.subjectsModule.fetchingSubject;
    },
    subject() {
      return this.$store.state.subjectsModule.subject;
    },
    show() {
      return this.$route.path.split("/").length == 3;
    },
  },

  data: () => {
    return {
      actions: [
        {
          name: "Modules",
          icon: "mdi-book-edit",
          to: "Modules",
          path: "modules",
          sub: "Lessons",
        },
        {
          name: "Grades",
          icon: "mdi-clipboard-list",
          to: "Grades",
          path: "grades",
          sub: "Assessment Grading",
        },
        {
          name: "Assessment",
          icon: "mdi-square-edit-outline",
          to: "Assessment",
          path: "assessment",
          sub: "Create/Assign",
        },
        {
          name: "Students",
          icon: "mdi-account-outline",
          to: "Students",
          path: "students",
          sub: "Tasks",
        },
      ],
    };
  },
  mounted() {
    //just re-route for now for control + click actions on breadcrumbs
    if (!this.subject) {
      this.$router.push("/");
    }
  },
};
</script>

<style></style>
